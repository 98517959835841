import React from 'react';
import Router from 'next/router';
import styled from 'styled-components';
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider';
import notFound from '../static/Not_Found.svg';
import GlobalStyle from '../components/Theme/Layout/GlobalStyle';
import FeatureFlagProvider from '../components/FeatureFlagProvider';
import Layout from '../components/Theme/Layout';
import PageFooter from '../components/Theme/PageFooter';
import { SCREEN_SIZE } from '../lib/Media';

const BottomLabelStyle = styled.div`
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  color: #696969;
`;

const TopLabelStyle = styled.div`
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: #333333;
`;

const ButttonStyle = styled.button`
  font-family: 'Lato', serif;
  height: 56px;
  display: block;
  min-width: 248px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 196px;
  background: #1557bf;
  border-radius: 28px;
`;

const ImgStyle = styled.img`
  display: block;
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.25px;
`;

const BlockCenter = styled.div`
  height: auto;
`;

const MainContainer = styled.div`
  background: #fff;
`;
const CardContainer = styled.div`
  ${SCREEN_SIZE.From.Tablet} {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin: 1rem auto 0;
  }

  ${SCREEN_SIZE.Only.Tablet} {
    > :first-child {
      width: 24.5rem;
    }
  }

  ${SCREEN_SIZE.Only.Mobile} {
    margin-bottom: '6rem';
  }

  ${SCREEN_SIZE.Only.MobileL} {
    margin-bottom: '6rem';
  }
`;

const WrpDiv = styled.div`
  ${SCREEN_SIZE.From.Tablet} {
    flex: 1;
    margin-bottom: 0;
  }

  ${SCREEN_SIZE.From.Desktop} {
    flex: 0;
    flex-basis: 100%;
    padding-bottom: 8px;
    max-width: 980px;
  }
`;

const ShowFooterSection = styled.div`
  border-top: 1px solid #eaeaea;
  ${SCREEN_SIZE.From.Tablet} {
    display: block;
    border-top: 1px solid #eaeaea;
  }
`;

const ReceiverPageContent = styled.div`
  max-width: 705px;
  margin: 0 auto;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li {
    margin: 0;
  }
`;

export default function FourZeroFour() {
  return (
    <FeatureFlagProvider data-testid="pageindex">
      <GlobalStyle />
      <Layout>
        <MainContainer>
          <CardContainer>
            <WrpDiv>
              <NdsThemeProvider>
                <ReceiverPageContent>
                  <BlockCenter>
                    <ImgStyle
                      data-testid="ErrElement"
                      className={notFound.Error404}
                      src={notFound.src}
                      alt={notFound}
                    />
                    <TopLabelStyle>
                      We can&apos;t seem to find the page you&apos;re looking for
                    </TopLabelStyle>
                    <BottomLabelStyle>Try searching keywords, or go to Home</BottomLabelStyle>
                    <ButttonStyle
                      data-testid="ButtonElement"
                      onClick={() => Router.push('/')}
                      type={notFound.Button404}
                    >
                      Back to Home
                    </ButttonStyle>
                  </BlockCenter>
                </ReceiverPageContent>
              </NdsThemeProvider>
            </WrpDiv>
          </CardContainer>
        </MainContainer>
      </Layout>
      <ShowFooterSection>
        <PageFooter />
      </ShowFooterSection>
    </FeatureFlagProvider>
  );
}
